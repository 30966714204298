.testimonials {
    background-color: lightblue;
    padding: 100px;
    color: black;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;    
}

.testimonials-white {
    background-color: white;
    padding: 100px;
    color: black;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden; 
}

.slider {
    overflow: hidden;
}

.slider__inner {
    display: flex;
    width: 300%;
    transition: all 0.5s ease;
}

.slider__contents {
    flex: 1;
    display: flex;
    flex-direction: row;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    width: calc(33.33%);
    border-radius: 15px;
    height: auto;
}

.slider__txt {
    margin-right: 100px;
}

.slider__radio {
    display: none;
}

#slide1:checked ~ .slider .slider__inner {
    transform: translateX(0%);
}

#slide2:checked ~ .slider .slider__inner {
    transform: translateX(-33.33%);
}

#slide3:checked ~ .slider .slider__inner {
    transform: translateX(-66.66%);
}

.slider__controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
}

.slider__label {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    transition: transform 0.3s ease;
    border: 2px solid transparent;
}

#slide1:checked ~ .slider__controls .slider__label:nth-child(1),
#slide2:checked ~ .slider__controls .slider__label:nth-child(2),
#slide3:checked ~ .slider__controls .slider__label:nth-child(3) {
    transform: scale(1.3);
    border: 2px solid #333;
}

.left-section-slider {
    flex: 1;
    overflow: hidden;
    padding: 10px;
    padding-top: 50px;
    align-items: center;
}

.left-section-slider img {
    width: 100%;
    height: auto;
    align-items: center;
}

.right-section-slider {
    flex: 2;
    padding: 50px;
}

.slider__image.fa {
    font-size: 18px;
}

/* Responsiveness */
@media only screen and (max-width: 768px) {
    .testimonials-white,
    .testimonials {
        padding: 50px;
    }

    .slider__inner {
        flex-direction: column;
        width: 100%;
    }

    .slider__contents {
        width: 100%;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .left-section-slider, .right-section-slider {
        flex: none;
        width: 100%;
        padding: 20px 0;
    }

    .slider__radio, .slider__controls {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .testimonials-white,
    .testimonials {
        padding: 20px;
    }

    .slider__contents {
        padding: 10px;
    }

    .slider__txt {
        margin-right: 0;
    }

    .right-section-slider {
        padding: 20px;
    }
}

