@keyframes infoPulse {
    0%, 100% {
        background-color: lightblue;
    }
    50% {
        background-color: #B0E2FF;
    }
}

.information-sections {
    background-color: lightblue;
    padding: 5% 5% 5% 100px; /* Retained 100px left padding for larger screens */
    color: black;
    width: 100%;
    animation: infoPulse 5s infinite;
}

.question-working,
.question-working-sub {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}

.question-working {
    font-size: 2rem;
}

.question-working-sub {
    font-size: 1.5rem;
}

.content-answer {
    margin-top: 1.5rem;
    font-size: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    max-width: 100%;
}

.text {
    text-align: justify;
    text-justify: inter-word;
    
}

.step-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem 0;
}

.step-grid-priv-pol {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem 0;
}

.step-edu-specialist,
.step-edu,
.step {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-priv-pol {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-edu-specialist,
.step-edu {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-edu-specialist,
.step-edu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step-edu:hover,
.step:hover {
    transform: scale(1.02);
}

.right-section {
    flex: 1;
    padding: 10px;
}

.left-section {
    flex: 1;
    width: 50%;
    height: 100%;
    overflow: hidden;
    padding-right: 20px;
}

.left-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gridlogo {
    max-width: 100%; 
    height: auto; 
    margin: 0.5rem 1rem 1rem 0;
}

.serviceslogo {
    float: right;
    width: 300px;
    height: 200px;
}

.step-edu-specialist-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.slide-content-spec {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
}

.slide-content-spec-testimonial {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 10px;
}

.logo-block-spec, .text-block-spec {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.gridlogo-spec {
    max-width: 100%;
    height: auto;
}

.gridlogo-spec-testimonials {
    max-width: 50%;
    height: auto;
}

.appointmentButton{
    background-color: white; 
    color: indigo !important;
    border: solid;
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    border-radius: 25px;
}

.img{
    display: flex;
    margin: 0 auto;
    height: 60vh;
    width: 100%;
    border-radius: 5%;
}

.hlink {
    color: black;
    transform: scale(1.02);
    
}

td {
    text-align: justify;
    padding: 0.5rem;
  }

.hlink:hover{
    color: blue;
}

#parent{ 
    display: flex; 

  } 
  .col{ 
    color: black;
    background-color: white;
    padding: 10px; 
    flex: 50%; 
    font-size: 20px;
    
  } 
  .col:nth-child(2){ 
    background-color: white;
  } 




/* Responsive for smaller screens */
@media (max-width: 768px) {
    .information-sections {
        padding: 5%; /* Adjusted padding for smaller screens */
    }

    .left-section {
        width: 100%; /* Left section takes full width on mobile */
        padding-right: 10px; /* Reduce padding for mobile */
    }

    .step-edu-specialist-main,
    .step-grid {
        grid-template-columns: 1fr;
    }

    .step-edu-specialist,
    .step-edu,
    .step {
        flex-direction: column;
        align-items: center;
    }

    .question-working {
        font-size: 1.5rem;
    }

    .question-working-sub {
        font-size: 1.2rem;
    }

    .content-answer {
        font-size: 0.8rem;
    }

    .right-section,
    .left-section {
        width: 100%;
        padding: 10px;
    }

    .left-section img {
        width: 100%;
        height: auto;
    }
}
